import Template from '../../templates/main.html';
import I18n from '../../utilities/i18n';
import DateTime from '../../utilities/date-time';
import HeaderFactory from './headers/header-factory';
import PreviewerFactory from './previewers/previewer-factory';

const whatsapp = function (dom, config) {
	const i18n = new I18n(config.locale);
	const previewerHeader = '#previewer-header';
	const previewerContainer = '#previewer-container';
	let previewer = null;
	let header = null;

	this.config = config || {};

	const setDefaultConfig = () => {
		const datetime = new DateTime(this.config.locale);
		this.config.i18n = i18n;
		this.config.date = datetime.getCurrentDate();
		this.config.time = datetime.getCurrentTime();
		this.config.channelName = i18n.get('jsonmessageblock.JSONMESSAGE_WHATSAPP').toUpperCase();
		this.config.timestamp = i18n.get('mobilepush_inapp.now');
	};

	const renderPreviewer = () => {
		const container = dom.querySelector(previewerContainer);
		previewer = PreviewerFactory.createPreviewer(container, config);
		previewer.render();
	};

	const render = () => {
		dom.innerHTML = Template;
	};

	const setupHeaderListeners = () => {
		header.onOrientationChange = (orientation) => {
			this.config.orientation = orientation;
			const iframe = dom.querySelector('iframe');
			if (iframe) {
				iframe.classList.toggle('landscape', orientation === 'landscape');
			}
			renderPreviewer();
		};

		header.onScreenPreviewChange = (screenPreview) => {
			this.config.screenPreview = screenPreview;
			renderPreviewer();
		};

		header.onFlowPreviewTypeChange = (previewType) => {
			this.config.previewType = previewType;
			renderPreviewer();
		};
	};

	const renderHeader = () => {
		const container = dom.querySelector(previewerHeader);
		header = HeaderFactory.createHeader(container, this.config);
		header.render();
		setupHeaderListeners();
	};

	const initialize = () => {
		setDefaultConfig();
		render();
		renderHeader();
		renderPreviewer();
	};

	initialize();

	this.render = (updates) => {
		Object.assign(this.config, updates);
		setDefaultConfig();
		renderHeader();
		renderPreviewer();
	};
};

export default whatsapp;
