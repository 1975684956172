import replaceUrlsInMessageString from './sms-utility';

const ParseContent = function (content) {
	this.content = content || {};
};

const getImagePlacholder = function () {
	return 'https://image.s8.exacttarget.com/lib/fe8e1c72746d057a70/m/1/3e70c283-1e2b-435d-9f35-50c1467c176e.png';
};

const getImage2Placholder = function () {
	return 'https://image.s8.sfmc-content.com/lib/fe8e1c72746d057a70/m/1/1d180222-e489-42a7-a4b5-6139a1145050.png';
};

const getVidoEmptyPlacholder = function () {
	return 'https://image.s8.sfmc-content.com/lib/fe8e1c72746d057a70/m/1/611cf8a2-275b-43f6-b9a7-b9332deb9170.png';
};

const getAudioPlacholder = function () {
	return 'https://image.s8.sfmc-content.com/lib/fe8e1c72746d057a70/m/1/d79fe4e7-727c-47fa-a290-6a0c111d96f7.png';
};

const getStickerUrl = function (filename) {
	if (!filename) {
		return '';
	}

	const { protocol, hostname, port } = window.location;
	const domain = hostname === 'localhost' ? `${hostname}:${port}` : hostname;
	const url = `${protocol}//${domain}/images/line/stickers/${filename}.png`;

	return url;
};

const parseLineCarousel = function (blockData) {
	const contents = blockData.contents || [];
	const { i18n, stackKey } = blockData;
	const messages = [];
	const imageSize = (blockData.globalSettings && blockData.globalSettings.imageSize) ? blockData.globalSettings.imageSize : '';
	const imageAspectRatio = (blockData.globalSettings && blockData.globalSettings.imageAspectRatio) ? blockData.globalSettings.imageAspectRatio : '';
	const mediaExists = Boolean(blockData.globalSettings && (blockData.globalSettings.image === 'on'));
	const titleExists = Boolean(blockData.globalSettings && (blockData.globalSettings.title === 'on'));
	const button1Exists = Boolean(blockData.globalSettings && (blockData.globalSettings.button1 === 'on'));
	const button2Exists = Boolean(blockData.globalSettings && (blockData.globalSettings.button2 === 'on'));
	const button3Exists = Boolean(blockData.globalSettings && (blockData.globalSettings.button3 === 'on'));
	const isSubscriberPreview = blockData.isSubscriberPreview || false;

	if (contents.length > 0) {
		contents.forEach((content) => {
			const message = {};

			message.imageSize = imageSize;
			message.imageAspectRatio = imageAspectRatio;
			message.message = content['display:message:display'] || '';
			message.backgroundColor = (content['messageStyle:backgroundColor'] && content['messageStyle:backgroundColor'].hex) ? content['messageStyle:backgroundColor'].hex : '';

			if (mediaExists) {
				message.mediaUrl = content['display:media:url'] || '';

				if (message.mediaUrl.length === 0) {
					message.backgroundColor = 'e3f7ff';
					message.imageSize = 'auto';
					message.mediaUrl = getImagePlacholder();
				}
			}

			if (titleExists) {
				message.title = content['display:title:display'] || '';

				if (message.title.length === 0) {
					message.title = i18n.get('mobilepush_inapp.give_your_message_a_title');
				}
			}

			if (message.message.length === 0) {
				message.message = i18n.get('mobilepush_inapp.add_your_message');
			}

			if (button1Exists) {
				message.button1 = content['button1:title'] || '';

				if (message.button1.length === 0) {
					message.button1 = i18n.get('mobilepush_inapp.name_button_1');
				}
			}

			if (button2Exists) {
				message.button2 = content['button2:title'] || '';

				if (message.button2.length === 0) {
					message.button2 = i18n.get('mobilepush_inapp.name_button_2');
				}
			}

			if (button3Exists) {
				message.button3 = content['button3:title'] || '';

				if (message.button3.length === 0) {
					message.button3 = i18n.get('mobilepush_inapp.name_button_3');
				}
			}

			messages.push(message);
		});
	}

	const data = {
		channel: blockData.channel || 'line',
		columns: messages,
		currentColumn: blockData.currentColumn || '0',
		isSubscriberPreview,
		i18n,
		stackKey
	};

	return data;
};

const parseLineMultiContent = function (blockData) {
	const contents = blockData.contents || [];
	const { i18n, stackKey } = blockData;
	const messages = [];
	const isSubscriberPreview = blockData.isSubscriberPreview || false;

	if (contents.length > 0) {
		contents.forEach((content) => {
			const { type } = content;

			let value = '';

			switch (type) {
				case 'text':
					value = content['display:text:display'];

					if ((isSubscriberPreview === false) && (!value)) {
						value = i18n.get('jsonmessageblock.JSONMESSAGE_TEXT_PLACEHOLDER');
					}
					break;

				case 'image':
					value = content['display:media:url'];

					if ((isSubscriberPreview === false) && (!value)) {
						value = getImage2Placholder();
					}
					break;

				case 'video':
					value = content['display:media:alt'];

					if ((isSubscriberPreview === false) && (!value)) {
						value = getVidoEmptyPlacholder();
					}
					break;

				case 'audio':
					value = content['display:duration'] || '';

					if ((isSubscriberPreview === false) && (!value)) {
						value = getAudioPlacholder();
					}
					break;

				case 'imagemap':
					value = content['display:media:url'];

					if ((isSubscriberPreview === false) && (!value)) {
						value = getImage2Placholder();
					}
					break;

				case 'sticker':
					value = getStickerUrl(content['display:stickerid']);
					break;

				default:
					break;
			}

			if (type) {
				const message = {
					type,
					value
				};

				if (type === 'imagemap') {
					message.layout = content['display:media:layout'];
					message.highlight = (typeof content['highlight:area:index'] === 'undefined') ? 0 : parseInt(content['highlight:area:index'], 10) + 1;
				}

				messages.push(message);
			}
		});
	}

	const data = {
		channel: blockData.channel || 'line',
		contents: messages,
		isSubscriberPreview,
		i18n,
		stackKey
	};

	return data;
};

// In case of session preview of list, function to check weather list is empty or not.
const isNonEmptyList = (sections) => {
	// Check if sections is null or undefined
	if (!sections) {
		return false;
	}
	// Check if the list is non-empty based on title and row content
	return sections.some((section) => (section.title && section.title.trim() !== '') || (section.rows?.some((row) => (row.title && row.title.trim() !== '') || (row.description && row.description.trim() !== ''))));
};

/**
 * Helper function to set the common config properties of flows and quick reply
 * @param {object} data - config object in which fields need to be set
 * @param {object} interactiveData - object from which selective fields need to be extracted
 * @param {const} i18n - to get the default value of the fields.
 */
const setCommonConfigProperties = (data, interactiveData, i18n) => {
	if (interactiveData) {
		data.type = interactiveData.type || '';
		data.header = { ...interactiveData.header || {} };
		data.header.previewLoadingText = i18n.get('jsonmessageblock.JSONMESSAGE_PREVIEW_LOADING');
		data.header.previewFallbackText = i18n.get('jsonmessageblock.JSONMESSAGE_VIDEO_URL_PREVIEW_FALLBACK');
		data.body = interactiveData.body?.text || i18n.get('jsonmessageblock.JSONMESSAGE_ENTER_BODY_TEXT');
		data.footer = interactiveData.footer?.text || '';
		data.action = interactiveData.action || {};
		data.placeHolderTypeMappings = {
			image: getImage2Placholder()
		};
	}
};

const parseWhatsAppSession = function (blockData) {
	const { i18n, stackKey, type } = blockData;
	const isSubscriberPreview = blockData.isSubscriberPreview || false;
	
	// Set up default data
	const data = {
		channel: blockData.channel || 'whatsappsession',
		template: blockData.template || 'whatsappsession',
		isSubscriberPreview,
		i18n,
		stackKey
	};
	
	// Set up content
	if (type.name) {
		switch (type.name) {
			case 'text': {
				data.type = 'text';
				data.message = blockData['display:message:display'] || '';

				if ((isSubscriberPreview === false) && (!data.message)) {
					data.message = i18n.get('jsonmessageblock.JSONMESSAGE_MESSAGE_PLACEHOLDER');
				}
				break;
			}
			case 'image': {
				const imageData = blockData['display:message:image'];
				if (imageData) {
					data.type = imageData.type;
					data.imageUrl = imageData['display:media:url'];
					data.imageCaption = imageData['display:media:caption'] || '';
					
					if ((isSubscriberPreview === false) && (!data.imageUrl)) {
						data.imageUrl = getImage2Placholder();
					}
				}
				break;
			}
			case 'document': {
				const documentData = blockData['display:message:document'];
				if (documentData) {
					data.type = documentData.type;
					data.documentUrl = documentData['display:media:url'];
					data.documentCaption = documentData['display:media:caption'] || '';
					data.documentFilename = documentData['display:media:filename'] || '';
					data.filesize = documentData['display:media:filesize'] || 0;
				}
				break;
			}
			case 'video': {
				const videoData = blockData['display:message:video'];
				if (videoData) {
					data.videoUrl = videoData['display:media:url'];
					data.isFileLoaded = videoData['display:media:isFileLoaded'];
					data.previewFallbackText = i18n.get('jsonmessageblock.JSONMESSAGE_VIDEO_URL_PREVIEW_FALLBACK');
					data.previewLoadingText = i18n.get('jsonmessageblock.JSONMESSAGE_PREVIEW_LOADING');
					data.type = videoData.type;
					data.videoCaption = videoData['display:media:caption'] || '';
				}
				break;
			}
			case 'list': {
				const listData = blockData['display:message:interactive:list'];
				if (listData) {
					data.type = listData.type || '';
					// Use optional chaining (?.) to safely access the properties
					data.header = listData?.header?.text || '';// Fallback to empty string if header or text is null
					data.body = listData?.body?.text || i18n.get('jsonmessageblock.JSONMESSAGE_ENTER_BODY_TEXT'); // Fallback to default string if listData.body is null or listData.body.text is null or listData.body.text is an empty string
					data.footer = listData?.footer?.text || '';
					data.listButton = listData?.action?.button || i18n.get('jsonmessageblock.JSONMESSAGE_ENTER_BUTTON_TEXT');
					data.sections = listData?.action?.sections || [];
					data.listPreview = false; // weather list to be shown or not(if list is empty or null in that case this will be false)

					// Use isNonEmptyList to check if the list is non-empty
					if (isNonEmptyList(data.sections)) {
						data.listPreview = true;

						// Perform section and row title validation only if data.sections is not empty
						data.sections.forEach((section) => {
							if (!section.title || section.title.trim() === '') {
								section.title = i18n.get('jsonmessageblock.JSONMESSAGE_ENTER_SECTION_TITLE');
							}

							// Safely handle cases where section.rows is null or undefined
							if (section.rows && section.rows.length > 0) {
								section.rows.forEach((row) => {
									if (!row.title || row.title.trim() === '') {
										row.title = i18n.get('jsonmessageblock.JSONMESSAGE_ENTER_ROW_TITLE');
									}
								});
							}
						});
					}
				}
				break;
			}
			case 'audio': {
				const audioData = blockData['display:message:audio'];
				// we don't support caption, filename or other metadata in the preview
				// only the info about whether audio URL was loaded or not is required for exception handling in preview
				if (audioData) {
					data.type = audioData.type;
					data.isFileLoaded = audioData['display:media:isFileLoaded'];
					data.audioUrl = audioData['display:media:url'];
					data.previewFallbackText = i18n.get('jsonmessageblock.JSONMESSAGE_AUDIO_URL_PREVIEW_FALLBACK');
					data.previewLoadingText = i18n.get('jsonmessageblock.JSONMESSAGE_PREVIEW_LOADING');
				}
				break;
			}
			case 'flow': {
				const flowData = blockData['display:message:interactive:flow'];
				setCommonConfigProperties(data, flowData, i18n);
				break;
			}
			case 'button': {
				const quickReplyData = blockData['display:message:interactive:button'];
				setCommonConfigProperties(data, quickReplyData, i18n);
				// null check for data, action and buttons array
				if (data?.action?.buttons?.length > 0) {
					data.action.buttons.forEach((button) => {
						// Ensure `button.reply` exists
						button.reply = button.reply || {}; // If `button.reply` is undefined, an empty object will be created.
						button.reply.title = button.reply?.title?.trim() || i18n.get('jsonmessageblock.JSONMESSAGE_ENTER_BUTTON_TEXT');
					});
				}
				break;
			}
			default:
				break;
		}
	}
	
	return data;
};

const parseWhatsAppTemplate = function (blockData) {
	const { channel, template, i18n, stackKey } = blockData;
	const isSubscriberPreview = blockData.isSubscriberPreview || false;
	const replaceParamsWithArgs = (data, params) => {
		if (!params) {
			return data;
		}
		
		let result = data;

		Object.keys(params).forEach((key) => {
			const param = params[key];
			const argument = param['display:argument'];
			
			if (!argument || argument.length === 0) {
				return;
			}
			
			result = result.replace(`$\{${param['display:argument:name']}}`, param['display:argument']);
		});
		
		return result;
	};
	
	// Set up default data
	const data = {
		channel: channel || 'whatsapptemplate',
		template: template || 'whatsapptemplate',
		isSubscriberPreview,
		i18n,
		stackKey
	};
	
	// Get components
	const components = blockData['display:components'];
	
	// Iterate components and set data for previewer;
	if (components) {
		components.forEach((component) => {
			switch (component) {
				case 'title': {
					const titleFormat = blockData['display:title:format'] || 'text';
					data.titleFormat = titleFormat.toLowerCase();
					
					switch (data.titleFormat) {
						case 'text':
							data.title = replaceParamsWithArgs(blockData['display:title'], blockData['display:title:parameters']);
							break;
						case 'image':
							data.title = blockData['display:title:parameters']['1']['display:argument']; // Header with image will always have one parameter
						
							if (!data.title || data.title.length === 0) {
								if (isSubscriberPreview) {
									data.title = '';
								} else {
									data.title = getImage2Placholder();
								}
							}
							break;
						case 'document':
							data.title = blockData['display:title:parameters']['1']['display:argument']; // Header with document will always have one parameter
							if (blockData['display:title:parameters']['1']['display:media:filename']) {
								data.filename = blockData['display:title:parameters']['1']['display:media:filename'];
							}
							if (blockData['display:title:parameters']['1']['display:media:filesize']) {
								data.filesize = blockData['display:title:parameters']['1']['display:media:filesize'];
							}
							break;
						case 'video':
							data.title = blockData['display:title:parameters']['1']['display:argument']; // Header with video will always have one parameter
							data.isFileLoaded = blockData['display:title:parameters']['1']['display:media:isFileLoaded'];
							data.previewFallbackText = i18n.get('jsonmessageblock.JSONMESSAGE_VIDEO_URL_PREVIEW_FALLBACK');
							data.previewLoadingText = i18n.get('jsonmessageblock.JSONMESSAGE_PREVIEW_LOADING');
							break;
						default:
							break;
					}
					break;
				}
				case 'message':
					data.message = replaceParamsWithArgs(blockData['display:message'], blockData['display:message:parameters']);
					break;
				case 'footer':
					data.footer = replaceParamsWithArgs(blockData['display:footer'], blockData['display:footer:parameters']);
					break;
				case 'buttons': {
					const buttonData = blockData['display:buttons'];
					const regularButtons = [];
					const quickReplyButtons = [];
					
					Object.keys(buttonData).forEach((key) => {
						const button = buttonData[key];
						const config = {
							id: button.id,
							actionType: button.actionType,
							label: button.title
						};
						if (button.actionType.toLowerCase() === 'quick_replies') {
							quickReplyButtons.push(config);
						} else {
							regularButtons.push(config);
						}
					});
					
					data.buttons = {
						regular: regularButtons,
						quickReplies: quickReplyButtons
					};
					
					break;
				}
				default:
					break;
			}
		});
	}

	if (blockData['display:flow:parameters']) {
		const flowData = blockData['display:flow:parameters'];
		data.flowPreviewUrlStatus = flowData.previewUrlStatus;
		data.flowPreviewUrl = flowData.previewUrl;
	}
	
	return data;
};

ParseContent.prototype.parseChannelConfig = function (channel) {
	switch (channel) {
		case 'push':
			return this.push();
		case 'inapp':
			return this.inapp();
		case 'sms':
			return this.sms();
		case 'line':
			return this.line();
		case 'whatsappsession':
		case 'whatsapptemplate':
			return this.whatsapp();
		default:
			return null;
	}
};

ParseContent.prototype.push = function () {
	const blockData = this.content;
	const { isSubscriberPreview, stackKey } = this.content;
	const actionType = blockData['openBehavior:actionType'];

	const data = {
		channel: blockData.channel || 'push',
		locale: blockData.locale || '',
		title: blockData['display:title:display'] || '',
		subtitle: blockData['display:subtitle:display'] || '',
		message: blockData['display:message:display'] || '',
		iosRichMedia: blockData['display:media:url:ios'] || '',
		androidRichMedia: blockData['display:media:url:android'] || '',
		iosAlternateText: blockData['display:media:alt:ios'] || '',
		androidAlternateText: blockData['display:media:alt:android'] || '',
		hiddenFields: blockData.uncheckedPanels || [],
		actionType: (actionType) ? actionType.value : '',
		openDirect: blockData['openBehavior:action'] || '',
		isSubscriberPreview: isSubscriberPreview || false,
		stackKey,
		template: blockData.template || ''
	};

	const { richFeatures, currentColumn } = blockData;

	if (richFeatures) {
		data.currentColumn = currentColumn;
		data.richFeatures = richFeatures;
	}

	return data;
};

ParseContent.prototype.inapp = function () {
};

ParseContent.prototype.sms = function () {
	const blockData = this.content;
	const codeType = blockData['display:previewbycodetype'] || {};
	const locale = codeType.locale || 'US';
	const { i18n, isSubscriberPreview, stackKey, isLinkShorteningEnabled, shortenerType, customDomain } = this.content;

	let supportsConcatenation = codeType.supportsConcatenation || false;

	if (supportsConcatenation === true) {
		supportsConcatenation = blockData['display:concatenate'] || false;
	}

	const urlReplacedMessage = replaceUrlsInMessageString(blockData['display:message:display'], isLinkShorteningEnabled, shortenerType, customDomain);
	const data = {
		channel: blockData.channel || 'sms',
		locale,
		concatenate: supportsConcatenation,
		message: urlReplacedMessage || '',
		isSubscriberPreview: isSubscriberPreview || false,
		stackKey
	};

	if ((!data.isSubscriberPreview) && (!data.message)) {
		data.message = i18n.get('jsonmessageblock.JSONMESSAGE_MESSAGE_PLACEHOLDER');
	}

	return data;
};

ParseContent.prototype.line = function () {
	const blockData = this.content;
	const { template } = blockData;

	let data;

	switch (template) {
		case 'lineMultiContent':
			data = parseLineMultiContent(blockData);
			break;

		default:
			data = parseLineCarousel(blockData);
			break;
	}

	data.template = template;

	return data;
};

ParseContent.prototype.whatsapp = function () {
	const blockData = this.content;
	const { channel } = blockData;
	let data;

	switch (channel) {
		case 'whatsapptemplate':
			data = parseWhatsAppTemplate(blockData);
			break;

		default:
			data = parseWhatsAppSession(blockData);
			break;
	}

	return data;
};

export default ParseContent;
